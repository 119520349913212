<template>
  <v-container fluid >
    <section id="info" class="mt-16">
      <v-row no-gutters>
        <v-col cols="12" sm="4" >
          <v-card
              class="elevation-0 mb-2 mt-2"
              max-width="400"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="overline mb-5">
                    Telegram
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Подключите нашего бота Telegram и расшифровывайте файлы через него!
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <span v-if="!this.telegram">
                  <v-btn
                      color="primary"
                      type="submit"
                      @click="disableTelegram"
                  >Отключить</v-btn>
              </span>
              <span v-else>
              <v-btn
                  color="success"
                  type="submit"
                  @click="connectTelegram"
              >Подключить</v-btn>
              </span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" ></v-col>
        <v-col
            cols="12"
            sm="4"
        >
          <v-card
              class="mx-auto elevation-1"
              max-width="400"
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="overline mb-5">
                    БАЛАНС
                  </div>
                </v-list-item-title>
                <div class="mb-2">
                  Досутпно врмени для распознавания: <span class="green--text">{{ balance }}</span>
                </div>
                <v-list-item-subtitle>
                  Цена: 1 час распознавания - 100 рублей.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-select
                  label="Количество часов"
                  class="select mr-2"
                  v-model="count"
                  :items="[1,2,3,4,5,6,7,8,9,10]"
              ></v-select>
              <v-btn
                  color="success"
                  type="submit"
                  @click="topUpBalance"
              >Пополнить</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </section>
    <section id="fileUpload">
      <v-flex xs12 sm6 offset-sm3 mt-13>
        <form class="upload form" @submit.prevent="upload" ref="upload" >
        <v-row no-gutters justify="center" align="center">
          <v-col cols="7">
            <v-file-input
                show-size
                accept="audio/*"
                ref="fileInput"
                label="Выберите файл"
                @change="selectFile"
            ></v-file-input>
            <span v-if="currentFile">
            <v-progress-linear
                v-model="progress"
                :value="progress"
                reactive
            >
            </v-progress-linear>
          </span>
          </v-col>

          <v-col cols="5" class="pl-2">
            <v-btn color="success" dark small type="submit">
              Загрузить
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        </form>
      </v-flex>
    </section>
    <section id="fileList">
      <v-data-table
          :headers="headers"
          :items="files"
          item-key="uuid"
          :items-per-page="5"
          class="elevation-0"
          show-expand
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          @item-expanded="loadDetails"
      >
        <template #item.link="{ item }">
          <span v-if="item.is_recognize">
            <v-btn
                icon
                color="success"
                type="submit"
                @click="download(item.name, item.uuid)"
                download
            >
            <v-icon color="green lighten-1">mdi-file-download-outline</v-icon>
          </v-btn>
          </span>

        </template>

        <template #item.actions="{ item }">
          <v-btn
              icon
              color="error"
              type="submit"
              @click="deleteFile(item.uuid)"
          >
            <v-icon color="red lighten-1">
              mdi-trash-can
            </v-icon>
          </v-btn>
        </template>

        <template #item.status="{ item }">
          {{ statusList[item.status] }}
          <v-tooltip right v-if="item.descriptor">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="toolTipShow = !toolTipShow"
              >
                <v-icon color="red lighten-1">
                  mdi-information-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.descriptor }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <v-btn icon @click="expand(true)" v-if="!isExpanded && item.is_recognize"><v-icon color="green lighten-1">mdi-eye-outline</v-icon></v-btn>
          <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon color="green lighten-1">mdi-eye-off-outline</v-icon></v-btn>
        </template>

        <template v-slot:expanded-item="{headers}">
          <td :colspan="headers.length">
            <vue-editor v-model="detail" :editorOptions="editorOptions"></vue-editor>
          </td>
        </template>

      </v-data-table>
    </section>
  </v-container>
</template>

<style>
.select {
  max-width: 150px;
}
</style>

<script>
import { VueEditor } from "vue2-editor";
import error from "@/constants/error"
import axios from 'axios';

export default {
  data() {
    return {
      files: [],
      expanded: [],
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      detail: "",
      telegram: "",
      balance: "",
      count: 1,
      toolTipShow: false,
      singleExpand: true,
      headers: [
        {
          text: 'Название файла',
          align: 'start',
          value: 'name',
        },
        { text: 'Время', value: 'time' },
        { text: 'Статус', value: 'status' },
        { text: '', value: 'link' },
        { text: '', value: 'data-table-expand' },
        { text: '', value: 'actions' },
      ],
      currentFile: undefined,
      progress: 0,
      statusList: {
        0: 'Создан',
        1: 'Завершен',
        2: 'В процессе',
        3: 'Ошибка',
      }
    };
  },
  head: {
    title: {
      inner: 'Личный кабинет',
      separator: '-',
      complement: 'Аудио записи в текст онлайн'
    },
    link: [
      {
        rel: "icon",
        href: require("@/assets/images/logo.png"),
        sizes: '32x32',
        type: 'image/png'
      },
    ]
  },
  methods: {
    getInfo() {
      axios.get(process.env.VUE_APP_API_URL + "/api/v1/profile/info")
          .then((res) => {
              this.files = res.data.data.files;
              this.balance = res.data.data.balance;
              this.telegram = res.data.data.telegram;
          })
          .catch((err) => {
              if (err.response.status === 401) {
                this.$store.dispatch('logout');
              } else {
                this.$store.dispatch("showSnack", {text: "Ошибка получения списка файлов!", color: "error"})
              }
          });
    },
    connectTelegram() {
      window.open(process.env.VUE_APP_TELEGRAM_URL+this.telegram, "_blank");
    },
    disableTelegram() {
      axios.get(process.env.VUE_APP_API_URL + "/api/v1/profile/disableTelegram")
          .then((res) => {
            this.telegram = res.data.data
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              this.$store.dispatch("showSnack", {text: "Неудалось отключить телеграм!", color: "error"})
            }
          });
    },
    topUpBalance() {
      axios.post(process.env.VUE_APP_API_URL + '/api/v1/payment/create', {"count": this.count})
          .then((res) => {
            window.location.href = res.data.data
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              this.$store.dispatch("showSnack", {text: "Пополнение счета в данный момент не досутпно!", color: "error"})
            }
          });
    },
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    deleteFile(uuid) {
      axios.get(process.env.VUE_APP_API_URL + '/api/v1/file/delete/' + uuid + '/')
          .then(() => {
            this.$store.dispatch("showSnack", {text: "Файл успешно удален!", color: "success"})
            this.getInfo()
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              this.$store.dispatch("showSnack", {text: "Ошибка удаления файла", color: "error"})
            }
          });
    },
    upload() {
      if (!this.currentFile) {
        this.$store.dispatch("showSnack", {text: "Пожалуйста, выберите файл!", color: "error"})
        return;
      }

      const options = {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: function(progressEvent) {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
      };

      let formData = new FormData();
      formData.append("file", this.currentFile);

      axios.post(process.env.VUE_APP_API_URL + "/api/v1/file/upload", formData, options)
          .then(() => {
            this.$store.dispatch("showSnack", {text: "Файл успешно загружен!", color: "success"})
            this.currentFile = undefined;
            this.getInfo()
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              this.progress = 0;
              this.currentFile = undefined;
              error.show_error(err.response.data.data)
              this.getInfo()
            }
          });

      this.$refs.upload.reset();
      this.$refs.fileInput.reset();
    },
    loadDetails({item}) {
        this.detail = ""
        axios.get(process.env.VUE_APP_API_URL + '/api/v1/file/detail/' + item.uuid + '/')
          .then((res) => {
            this.detail = res.data.data;
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              error.show_error(err.response.data.data)
            }
          });
    },
    download(name, uuid) {
      axios.get(process.env.VUE_APP_API_URL + '/api/v1/file/download/' + uuid + '/')
          .then(function(response) {
            let blob = new Blob([response.data], {type: 'application/text'})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = name + '.txt'
            link.click()
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$store.dispatch('logout');
            } else {
              error.show_error(err.response.data.data)
            }
          });
    }

  },
  components: {
    VueEditor,
  },
  created() {
    this.getInfo()
    this.interval = setInterval(() => this.getInfo(), 5000);
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
};
</script>
