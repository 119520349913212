import store from '@/store'

export default {
    error_list: {
        user_not_found: 'Пользователь не найден',
        invalid_file_format: 'Неверный формат файла',
        invalid_balance: 'Недостаточно средств',
        username_exist: 'Пользователь уже существует',
        invalid_request: 'Неверный запрос',
        invalid_token: 'Неверный токен',
        invalid_captcha: 'Неверная капча',
        invalid_policy: 'Вы не приняли пользовательское соглашение',
    },

    show_error(data) {
        store.dispatch(
            "showSnack",
            {text: this.error_list[data] ? this.error_list[data] : 'Неверный запрос', color: "error"}
        )
    }
}
